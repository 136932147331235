import { useQuery } from '@apollo/client';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { gql } from '__generated__/gql';
import htmlTruncate from 'html-truncate';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import FocusLayout from 'layouts/FocusLayout';

import ErrorMessage from 'components/ErrorMessage';
import Statistics from 'components/Statistics';

import LoadingIndicator from 'primitives/LoadingIndicator';

import constants from 'utils/constants';

import FundInvestments from './Investments';

const FUND_QUERY = gql(`
  query GetFundBasic($id: ID!) {
    fund(id: $id) {
      id
      name
      description
      image
      userFundClosing {
        id
        amount
        finalisedAt
      }
    }
  }
`);

const FundPage: FC = () => {
  const { fundId } = useParams<{ fundId: string }>() as { fundId: string };
  const navigate = useNavigate();

  const { loading, error, data, refetch } = useQuery(FUND_QUERY, {
    variables: {
      id: fundId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const fund = data.fund;

  if (!fund.userFundClosing) {
    return <p className="text-gray-500">You do not have access to this fund</p>;
  }

  const FundHeader = (
    <div className="flex items-start mb-6">
      <button
        type="button"
        onClick={() => navigate('/invest')}
        className="mr-4 rounded-full bg-gray-300 p-1 text-gray-900 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        <ArrowLeftIcon className="h-6 w-6" aria-hidden="true" />
        <span className="sr-only">Back</span>
      </button>

      <div className="flex items-start space-x-5">
        <div className="flex-shrink-0">
          <div className="relative">
            <img
              className="h-16 w-16 rounded-lg object-contain bg-gray-200"
              src={fund.image || constants.ALT_AVATAR_URL}
              alt=""
            />
            <span className="absolute inset-0 rounded-lg shadow-inner" aria-hidden="true" />
          </div>
        </div>
        <div>
          <h1 className="text-2xl font-bold text-gray-900">{fund.name}</h1>
          <p
            className="text-md font-medium text-gray-500"
            dangerouslySetInnerHTML={{
              __html: htmlTruncate(fund.description || '', 200),
            }}
          />
        </div>
      </div>
    </div>
  );

  return (
    <FocusLayout>
      {FundHeader}
      <Statistics
        statistics={[
          {
            label: 'Amount',
            value: fund.userFundClosing.amount,
            type: 'CURRENCY',
          },
          {
            label: 'Invested At',
            value: fund.userFundClosing.finalisedAt,
            type: 'DATE',
          },
        ]}
      />
      <FundInvestments fundClosingId={fund.userFundClosing.id} />
    </FocusLayout>
  );
};

export default FundPage;
