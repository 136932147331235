import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import classNames from 'classnames';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import FocusLayout from 'layouts/FocusLayout';

import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import constants from 'utils/constants';

import Commitments from './Commitments';
import DealInformation from './DealInformation';

const DEAL_QUERY = gql(`
  query GetDealBasic($id: ID!) {
    deal(id: $id) {
      id
      companyInformation {
        company {
          name
          image
        }
      }
      syndicate {
        id
        name
      }
      userClosings {
        nodes {
          id
        }
      }
    }
  }
`);

const DealPage: FC = () => {
  const { dealId } = useParams<{ dealId: string }>() as { dealId: string };
  const [activeTab, setActiveTab] = useState('overview');

  const { loading, error, data, refetch } = useQuery(DEAL_QUERY, {
    variables: {
      id: dealId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const deal = data.deal;

  if (!deal) {
    return (
      <ErrorMessage
        title="Invalid Deal Invite"
        message="There is an issue with this deal invite. Please contact the syndicate lead for a new invite."
      />
    );
  }

  const hasCommitments = deal.userClosings && deal.userClosings.nodes.length > 0;

  const CompanyHeader = (
    <div className="flex items-start space-x-5 mb-6">
      <div className="flex-shrink-0">
        <div className="relative">
          <img
            className="h-16 w-16 rounded-lg object-contain bg-white"
            src={deal.companyInformation.company.image || constants.ALT_AVATAR_URL}
            alt=""
          />
          <span className="absolute inset-0 rounded-lg shadow-inner" aria-hidden="true" />
        </div>
      </div>
      <div>
        <h1 className="text-3xl font-bold text-gray-900">{deal.companyInformation.company.name}</h1>
        <p className="text-md font-medium text-gray-500">{deal.syndicate.name}</p>
      </div>
    </div>
  );

  if (hasCommitments) {
    const tabs = [
      { key: 'overview', label: 'Overview', description: 'View your commitments' },
      { key: 'information', label: 'Information', description: 'Deal details' },
    ];

    return (
      <FocusLayout>
        {CompanyHeader}

        <div className="mb-6">
          <nav className="flex gap-x-1 overflow-x-auto" aria-label="Tabs">
            {tabs.map(tab => (
              <button
                key={tab.key}
                onClick={() => setActiveTab(tab.key)}
                className={classNames(
                  activeTab === tab.key
                    ? 'bg-indigo-100 text-indigo-700'
                    : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50',
                  'rounded-md px-3 py-2 text-sm font-medium text-nowrap min-w-fit'
                )}
                aria-current={activeTab === tab.key ? 'page' : undefined}
              >
                {tab.label}
              </button>
            ))}
          </nav>
        </div>

        {activeTab === 'overview' ? (
          <Commitments dealId={deal.id} />
        ) : (
          <DealInformation dealId={deal.id} />
        )}
      </FocusLayout>
    );
  }

  return (
    <FocusLayout>
      {CompanyHeader}
      <DealInformation dealId={deal.id} />
    </FocusLayout>
  );
};

export default DealPage;
