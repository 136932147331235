import {
  CheckBadgeIcon,
  CheckCircleIcon,
  CurrencyRupeeIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/solid';
import { ClosingStatusEnumType, FundingSourceEnumType } from '__generated__/graphql';
import dayjs from 'dayjs';
import { FC } from 'react';

import fundingSourceToReadable from 'utils/enums/funding-source-to-readable';
import { formatCurrency } from 'utils/format-helper';

import ReportIssueDealPage from './ReportIssueDealPage';

interface CommitmentStatusProps {
  id: string;
  amount: number;
  investmentEntity: {
    id: string;
    name: string;
  };
  fundingSource: FundingSourceEnumType;
  createdAt: string;
  finalisedAt?: string | null;
  companyName: string;
  status: ClosingStatusEnumType;
}

const getStatusConfig = (
  amount: number,
  companyName: string,
  createdAt: string,
  finalisedAt?: string | null
) => {
  const formattedAmount = formatCurrency(amount);
  const formattedCreatedAt = createdAt ? dayjs(createdAt).format('MMMM DD, YYYY') : null;
  const formattedFinalisedAt = finalisedAt ? dayjs(finalisedAt).format('MMMM DD, YYYY') : null;

  return {
    [ClosingStatusEnumType.Committed]: {
      icon: CheckCircleIcon,
      iconColor: 'text-amber-500',
      heading: `${formattedAmount} committed to ${companyName}`,
      description:
        'We’ve notified the founder/syndicate lead of your commitment. Wiring instructions will be sent to you once they confirm your investment, and the remaining commitments have been received.',
      dateLabel: 'Committed at',
      dateValue: formattedCreatedAt,
    },
    [ClosingStatusEnumType.Transferred]: {
      icon: CheckCircleIcon,
      iconColor: 'text-amber-500',
      heading: `${formattedAmount} committed to ${companyName}`,
      description:
        'Your funds have been received and are being processed. You will be notified when your investment is finalized and the deal is closed.',
      dateLabel: 'Committed at',
      dateValue: formattedCreatedAt,
    },
    [ClosingStatusEnumType.Active]: {
      icon: CheckBadgeIcon,
      iconColor: 'text-green-600',
      heading: `${formattedAmount} invested in ${companyName}`,
      description: 'Your investment has been finalized. You can find more details below.',
      dateLabel: 'Invested at',
      dateValue: formattedFinalisedAt,
    },
    [ClosingStatusEnumType.Exited]: {
      icon: CurrencyRupeeIcon,
      iconColor: 'text-green-600',
      heading: `${formattedAmount} investment in ${companyName} has been exited`,
      description: 'The exit summary and distribution details are available below.',
      dateLabel: 'Finalised at',
      dateValue: formattedFinalisedAt,
    },
    [ClosingStatusEnumType.PartiallyExited]: {
      icon: CurrencyRupeeIcon,
      iconColor: 'text-green-600',
      heading: `Your investment in ${companyName} has been partially exited`,
      description: 'The exit summary and distribution details are available below.',
      dateLabel: 'Finalised at',
      dateValue: formattedFinalisedAt,
    },
    default: {
      icon: QuestionMarkCircleIcon,
      iconColor: 'text-amber-500',
      heading: `${formattedAmount} investment in ${companyName}`,
      description:
        'Status information is currently unavailable. Please check back later or contact support.',
      dateLabel: 'Recorded on',
      dateValue: formattedCreatedAt,
    },
  };
};

const CommitmentStatus: FC<CommitmentStatusProps> = ({
  amount,
  investmentEntity,
  fundingSource,
  createdAt,
  finalisedAt,
  companyName,
  status,
}) => {
  const statusConfigs = getStatusConfig(amount, companyName, createdAt, finalisedAt);
  const config = statusConfigs[status] || statusConfigs.default;
  const { icon: Icon, iconColor, heading, description, dateLabel, dateValue } = config;

  return (
    <div className="custom-bounce">
      <div className="border-b border-t border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border p-4">
        <div className="flex items-center sm:items-start">
          <Icon aria-hidden="true" className={`h-16 w-16 ${iconColor}`} />
          <div className="ml-6 flex-1">
            <h5 className="text-2xl font-medium text-gray-900">{heading}</h5>

            {description && <p className="text-gray-500">{description}</p>}

            <dl className="mt-4 pt-4 flex flex-row gap-x-6 text-sm border-t border-gray-100">
              <div className="hidden sm:block">
                <dt className="font-medium text-gray-900">Investment Entity</dt>
                <dd className="mt-1 text-gray-500">{investmentEntity.name}</dd>
              </div>
              <div>
                <dt className="font-medium text-gray-900">Funding Source</dt>
                <dd className="mt-1 font-medium text-gray-500">
                  {fundingSourceToReadable(fundingSource).label}
                </dd>
              </div>
              <div>
                <dt className="font-medium text-gray-900">{dateLabel}</dt>
                <dd className="mt-1 text-gray-500">
                  {dateValue && <time dateTime={dateValue}>{dateValue}</time>}
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="flex justify-end">
          <ReportIssueDealPage />
        </div>
      </div>
    </div>
  );
};

export default CommitmentStatus;
