import { useQuery } from '@apollo/client';
import { gql } from '__generated__';
import { ClosingStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';

import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import CommitmentStatus from './CommitmentStatus';

const CLOSINGS_QUERY = gql(`
  query getCommitmentsOfUserForDeal($dealId: ID!, $filters: ClosingsFilterType) {
    deal(id: $dealId) {
      id
      userClosings(filters: $filters) {
        nodes {
          id
          investmentEntity {
            id
            name
          }
          amount
          status
          fundingSource
          createdAt
          finalisedAt
          statistics {
            id
            unrealisedValue
            realisedValue
            multipleOnInvestment
          }
          closingDocuments {
            id
            type
            name
            url
          }
          deal {
            companyInformation {
              company {
                name
              }
            }
          }
        }
      }
    }
  }
`);

const Commitments: FC<{ dealId: string }> = ({ dealId }) => {
  const { loading, error, data, refetch } = useQuery(CLOSINGS_QUERY, {
    variables: {
      filters: {
        statuses: [
          ClosingStatusEnumType.Committed,
          ClosingStatusEnumType.Transferred,
          ClosingStatusEnumType.Active,
          ClosingStatusEnumType.Exited,
          ClosingStatusEnumType.PartiallyExited,
        ],
      },
      dealId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const commitments = data.deal.userClosings?.nodes || [];

  return (
    <>
      <div className="custom-bounce">
        <div className="mt-8">
          <div className="mx-auto">
            <div className="mx-auto max-w-2xl space-y-8 sm:px-4 lg:max-w-4xl lg:px-0">
              {commitments.map(commitment => (
                <>
                  <CommitmentStatus
                    key={commitment.id}
                    id={commitment.id}
                    amount={commitment.amount}
                    investmentEntity={commitment.investmentEntity}
                    fundingSource={commitment.fundingSource}
                    createdAt={commitment.createdAt}
                    companyName={commitment.deal.companyInformation.company.name}
                    status={commitment.status}
                    finalisedAt={commitment.finalisedAt}
                  />
                  {/* TODO: Add closing documents later when investor statement + scheme doc is ready */}
                  {/* <p className="text-md font-bold mt-4 mb-2">Documents related to this closing</p>
                  <DataTable
                    data={commitment.closingDocuments}
                    columns={[
                      {
                        label: 'Name',
                        fieldName: 'name',
                      },
                      {
                        label: 'Type',
                        fieldName: 'type',
                        type: 'INVESTMENT_ENTITY_DOCUMENT_TYPE',
                      },
                      {
                        label: 'Url',
                        fieldName: 'url',
                        type: 'LINK',
                      },
                    ]}
                  /> */}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Commitments;
