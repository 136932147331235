import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { ClosingStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';
import FilterBar from 'components/FilterBar';
import ReportDataDiscrepancy from 'components/ReportDataDiscrepancy';

import LoadingIndicator from 'primitives/LoadingIndicator';

const CLOSINGS_QUERY = gql(`
  query getCompletedCommitmentsOfFundClosing(
    $id: ID!
    $cursor: ID
    $limit: Int
    $filters: ClosingsFilterType
  ) {
    fundClosing(id: $id) {
      id
      statistics {
        investmentsStatisticsBreakdownByStatus(
          statuses: [ACTIVE, EXITED, PARTIALLY_EXITED],
          fundClosingId: $id
        ) {
          status
          numberOfClosings
        }
      }
      investments(cursor: $cursor, limit: $limit, filters: $filters) {
        nodes {
          id
          deal {
            id
            companyInformation {
              id
              company {
                id
                name
                image
              }
            }
          }
          currentAmount
          exitedAmount
          status
          wiredAt
          statistics {
            id
            unrealisedValue
            multipleOnInvestment
            realisedValue
          }
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
    }
  }
`);

const FundInvestments: FC<{ fundClosingId: string }> = ({ fundClosingId }) => {
  const { loading, error, data, refetch, fetchMore, variables } = useQuery(CLOSINGS_QUERY, {
    variables: {
      id: fundClosingId,
      filters: {
        statuses: [ClosingStatusEnumType.Active, ClosingStatusEnumType.PartiallyExited],
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const closings = data.fundClosing.investments.nodes;
  const pageInfo = data.fundClosing.investments.pageInfo;

  const activeClosings =
    data.fundClosing.statistics.investmentsStatisticsBreakdownByStatus.find(
      status => status.status === ClosingStatusEnumType.Active
    )?.numberOfClosings ?? 0;

  const partiallyExitedClosings =
    data.fundClosing.statistics.investmentsStatisticsBreakdownByStatus.find(
      status => status.status === ClosingStatusEnumType.PartiallyExited
    )?.numberOfClosings ?? 0;

  const exitedClosings =
    data.fundClosing.statistics.investmentsStatisticsBreakdownByStatus.find(
      status => status.status === ClosingStatusEnumType.Exited
    )?.numberOfClosings ?? 0;

  const filters = [
    ...(activeClosings + partiallyExitedClosings > 0
      ? [
          {
            label: `Active (${activeClosings + partiallyExitedClosings})`,
            value: [ClosingStatusEnumType.Active, ClosingStatusEnumType.PartiallyExited],
          },
        ]
      : []),
    ...(exitedClosings + partiallyExitedClosings > 0
      ? [
          {
            label: `Exited (${exitedClosings + partiallyExitedClosings})`,
            value: [ClosingStatusEnumType.Exited, ClosingStatusEnumType.PartiallyExited],
          },
        ]
      : []),
  ];

  return (
    <>
      <FilterBar
        filters={filters}
        currentValue={
          (variables?.filters?.statuses as string[]) || [
            ClosingStatusEnumType.Active,
            ClosingStatusEnumType.PartiallyExited,
          ]
        }
        onSelect={value => {
          refetch({
            filters: {
              statuses: value as ClosingStatusEnumType[],
            },
          });
        }}
      />
      <div className="h-1" />
      <DataTable
        data={closings}
        emptyListTitle="Your haven't made any investments yet"
        columns={[
          {
            label: 'Company',
            fieldName: 'deal.companyInformation.company',
            type: 'IMAGE_WITH_NAME',
          },
          {
            label: 'Status',
            fieldName: 'status',
            type: 'STATUS',
          },
          {
            label: 'Invest Date',
            fieldName: 'wiredAt',
            type: 'DATE',
          },
          ...(variables?.filters?.statuses?.includes(ClosingStatusEnumType.Active)
            ? [
                {
                  label: 'Invested',
                  fieldName: 'currentAmount',
                  type: 'CURRENCY' as const,
                  note: {
                    text: 'Balance amount after the partial exit',
                    conditionToShow: rowData =>
                      rowData.status === ClosingStatusEnumType.PartiallyExited,
                  },
                },
                {
                  label: 'Unrealised Value',
                  fieldName: 'statistics.unrealisedValue',
                  type: 'CURRENCY' as const,
                },
                {
                  label: 'Multiple',
                  fieldName: 'statistics.multipleOnInvestment',
                  type: 'MULTIPLE' as const,
                },
              ]
            : []),
          ...(variables?.filters?.statuses?.includes(ClosingStatusEnumType.Exited)
            ? [
                {
                  label: 'Invested',
                  fieldName: 'exitedAmount',
                  type: 'CURRENCY' as const,
                  note: {
                    text: 'The portion of the investment that was exited',
                    conditionToShow: rowData =>
                      rowData.status === ClosingStatusEnumType.PartiallyExited,
                  },
                },
                {
                  label: 'Distributed Amount',
                  fieldName: 'statistics.realisedValue',
                  type: 'CURRENCY' as const,
                  headerNote: 'Post-carry, post-TDS amount remitted to your bank account',
                },
              ]
            : []),
        ]}
        hasNextPage={pageInfo.hasNextPage}
        onLoadMore={() =>
          fetchMore({
            variables: {
              cursor: pageInfo.cursor,
            },
          })
        }
      />
      <ReportDataDiscrepancy />
    </>
  );
};

export default FundInvestments;
